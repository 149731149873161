@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --toastify-icon-color-warning: #ef02fa;
  --toastify-icon-color-error: #ef02fa;
  --toastify-icon-color-success: #191a36;
}

* {
  @apply transition-colors duration-200;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide up and down arrows for input type number on Chrome, Safari, Edge, Opera */
.no-spin-buttons::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide up and down arrows for input type number on Firefox */
.no-spin-buttons[type=number] {
  -moz-appearance: textfield;
}

.react-calendar__month-view__days__day abbr {
  display: none;
}

.react-calendar__month-view__days__day {
  padding: 0 !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.react-calendar__month-view__days__day--weekend:not([disabled]):not(
    .react-calendar__month-view__days__day--neighboringMonth
  ) {
  color: black !important;
}

.animate-svg {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  -webkit-animation: draw 2s ease-in-out forwards;
  -o-animation: draw 2s ease-in-out forwards;
  animation: draw 2s ease-in-out forwards;
  animation-delay: 0.5s;
}

@keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-webkit-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-o-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@-moz-keyframes draw {
  to {
    stroke-dashoffset: 0;
  }
}

@font-face {
  font-family: "ginto-light";
  src: url('/public/fonts/ABCGintoNormal-Light.otf') format('opentype'), /* IE6-IE8 */
       url('/public/fonts/ABCGintoNormal-Light.woff2') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/ABCGintoNormal-Light.woff') format('woff'); /* Pretty Modern Browsers */
}

@font-face {
  font-family: "ginto-bold";
  src: url('/public/fonts/ABCGintoNormal-Bold.otf') format('opentype'), /* IE6-IE8 */
       url('/public/fonts/ABCGintoNormal-Bold.woff2') format('woff2'), /* Super Modern Browsers */
       url('/public/fonts/ABCGintoNormal-Bold.woff') format('woff'); /* Pretty Modern Browsers */
}

.disabled-link {
  pointer-events: none;
}
a[disabled] {
  pointer-events: none;
}
@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.animate-grow {
  animation: grow 0.5s ease-out forwards;
}

.Toastify__toast--error::after,
.Toastify__toast--warning::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  @apply bg-sb-light-pink;
}
.Toastify__progress-bar--error,
.Toastify__progress-bar--warning {
  @apply bg-sb-pink;
}
.Toastify__toast--error,
.Toastify__toast--warning {
  @apply border border-sb-pink;
}
.Toastify__toast--success {
  @apply bg-sb-purple;
}
.Toastify__progress-bar--success {
  @apply bg-sb-dark-purple;
}
.Toastify__toast-container {
  @apply sm:mt-0 mt-[10px] gap-[10px] sm:gap-0;
}
